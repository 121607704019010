import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { CustomerDashboardStore } from 'types/CustomerDashboard';

const initialState: CustomerDashboardStore = {
  install_status: '',
  job_id: '',
  quote_id: '',
};

export const customerDashboard = createSlice({
  name: 'customerDashboard',
  initialState,
  reducers: {
    updateByPayload: (state, action: PayloadAction<CustomerDashboardStore>) => {
      state = merge(state, action.payload);
    },
  },
});

export const { updateByPayload } = customerDashboard.actions;

export default customerDashboard.reducer;
